<template>
	<div id="app">
		<div class="bg">
			<div class="navb w" v-show="yy">
				<div class="navb-left"></div>
				<el-select
					v-model="value"
					placeholder="select"
					size="small"
					@change="isLanguage"
				>
					<el-option
						v-for="item in options"
						:key="item.value"
						:label="item.label"
						:value="item.value"
					>
					</el-option>
				</el-select>
			</div>

			<div class="navb w" v-show="ay">
				<el-select
					v-model="value"
					placeholder="select"
					size="small"
					@change="isLanguage"
				>
					<el-option
						v-for="item in options"
						:key="item.value"
						:label="item.label"
						:value="item.value"
					>
					</el-option>
				</el-select>
				<div class="navb-left"></div>
			</div>
			<div class="w">
				<div class="text" v-show="yy">
					Enjoy party with people around the world anytime, anywhere
				</div>
				<div class="text text_a" v-show="ay">
					استمتع بالحفلات مع أشخاص من أنحاء العالم في أي وقت وفي أي
					مكان
				</div>
				<!-- 地图 -->
				<div class="world" v-show="yy">
					<!-- 连接 -->
					<div class="connection"></div>
					<div class="picture_1">
						<div class="photo"></div>

						<div class="pulse1"></div>
						<div class="pulse2"></div>
						<div class="pulse3"></div>
					</div>
					<div class="picture_2">
						<div class="photo"></div>

						<div class="pulse1"></div>
						<div class="pulse2"></div>
						<div class="pulse3"></div>
					</div>
					<div class="picture_3">
						<div class="photo"></div>

						<div class="pulse1"></div>
						<div class="pulse2"></div>
						<div class="pulse3"></div>
					</div>
					<div class="picture_4">
						<div class="photo"></div>

						<div class="pulse1"></div>
						<div class="pulse2"></div>
						<div class="pulse3"></div>
					</div>
					<div class="picture_5">
						<div class="photo"></div>

						<div class="pulse1"></div>
						<div class="pulse2"></div>
						<div class="pulse3"></div>
					</div>
				</div>
				<div class="world_a" v-show="ay">
					<!-- 连接 -->
					<div class="connection"></div>
					<div class="picture_1">
						<div class="photo"></div>
						<div class="pulse1"></div>
						<div class="pulse2"></div>
						<div class="pulse3"></div>
					</div>
					<div class="picture_2">
						<div class="photo"></div>

						<div class="pulse1"></div>
						<div class="pulse2"></div>
						<div class="pulse3"></div>
					</div>
					<div class="picture_3">
						<div class="photo"></div>

						<div class="pulse1"></div>
						<div class="pulse2"></div>
						<div class="pulse3"></div>
					</div>
					<div class="picture_4">
						<div class="photo"></div>

						<div class="pulse1"></div>
						<div class="pulse2"></div>
						<div class="pulse3"></div>
					</div>
					<div class="picture_5">
						<div class="photo"></div>

						<div class="pulse1"></div>
						<div class="pulse2"></div>
						<div class="pulse3"></div>
					</div>
				</div>
				<!-- 手机盒 -->
				<div class="phone-box-hind" v-show="yy">
					<div class="phone-box">
						<!-- <div class="bg-box"></div> -->
						<!-- 英语 -->
						<el-carousel
							v-show="yy"
							height="38.4vw"
							indicator-position="none"
							arrow="never"
						>
							<el-carousel-item>
								<img src="./assets/1.png" alt="" />
							</el-carousel-item>
							<el-carousel-item>
								<img src="./assets/2.png" alt="" />
							</el-carousel-item>
							<el-carousel-item>
								<img src="./assets/3.png" alt="" />
							</el-carousel-item>
							<el-carousel-item>
								<img src="./assets/4.png" alt="" />
							</el-carousel-item>
						</el-carousel>
						<!-- 啊语 -->
						<el-carousel
							v-show="ay"
							height="38.4vw"
							indicator-position="none"
							arrow="never"
						>
							<el-carousel-item>
								<img src="./assets/1_ar.png" alt="" />
							</el-carousel-item>
							<el-carousel-item>
								<img src="./assets/2_ar.png" alt="" />
							</el-carousel-item>
							<el-carousel-item>
								<img src="./assets/3_ar.png" alt="" />
							</el-carousel-item>
							<el-carousel-item>
								<img src="./assets/4_ar.png" alt="" />
							</el-carousel-item>
						</el-carousel>
					</div>
				</div>

				<div class="phone-box-hind_a" v-show="ay">
					<div class="phone-box">
						<!-- <div class="bg-box"></div> -->
						<!-- 英语 -->
						<el-carousel
							v-show="yy"
							height="38.4vw"
							indicator-position="none"
							arrow="never"
						>
							<el-carousel-item>
								<img src="./assets/1.png" alt="" />
							</el-carousel-item>
							<el-carousel-item>
								<img src="./assets/2.png" alt="" />
							</el-carousel-item>
							<el-carousel-item>
								<img src="./assets/3.png" alt="" />
							</el-carousel-item>
							<el-carousel-item>
								<img src="./assets/4.png" alt="" />
							</el-carousel-item>
						</el-carousel>
						<!-- 啊语 -->
						<el-carousel
							v-show="ay"
							height="38.4vw"
							indicator-position="none"
							arrow="never"
						>
							<el-carousel-item>
								<img src="./assets/1_ar.png" alt="" />
							</el-carousel-item>
							<el-carousel-item>
								<img src="./assets/2_ar.png" alt="" />
							</el-carousel-item>
							<el-carousel-item>
								<img src="./assets/3_ar.png" alt="" />
							</el-carousel-item>
							<el-carousel-item>
								<img src="./assets/4_ar.png" alt="" />
							</el-carousel-item>
						</el-carousel>
					</div>
				</div>
			</div>

			<div class="bottom-filex">
				<!-- 下载按钮 -->
				<!-- 英语 -->
				<div class="download w" v-show="yy">
					<div class="goodle-paly" @click="download('google')"></div>
					<div class="app-store" @click="download('app')"></div>
					<div class="huawei" @click="download('huawei')"></div>
					<div class="apk" @click="download('apk')"></div>
				</div>
				<div class="download w download_a" v-show="ay">
					<div
						class="goodle-paly_a"
						@click="download('google')"
					></div>
					<div class="app-store_a" @click="download('app')"></div>
					<div class="huawei_a" @click="download('huawei')"></div>
					<div class="apk_a" @click="download('apk')"></div>
				</div>
				<div :class="yy ? 'sevice' : 'sevices'">
					<p @click="download('service')">
						{{ yy ? "Terms of service" : " شروط الخدمة" }}
					</p>
					<p>|</p>
					<p @click="download('Policy')">
						{{ yy ? "Privacy Policy" : " سياسة الخصوصية" }}
					</p>
					<p>|</p>
					<p @click="download('Abouts')">Abouts us</p>
					<p>|</p>
					<p @click="download('Contact')">Contact us</p>
					<p>|</p>
					<p @click="download('Refunds')">Refunds policy</p>
					<p>|</p>
					<p @click="download('Intellectual')">
						Intellectual Property Rights
					</p>
				</div>

				<span class="version"
					>Copyright ©2018 Link world Team, All Rights Reserved</span
				>
			</div>
			<!-- 弹出 -->
			<div v-if="out" class="eject"></div>
			<div v-if="out" class="eject_center">
				<p
					:class="yy ? 'boble' : 'bobles'"
					v-for="(item, i) in txt_"
					:key="i"
				>
					{{ yy ? item.en : item.en }}
				</p>
				<img
					@click="out = false"
					class="imgs"
					src="./assets/out.png"
					alt=""
				/>
			</div>
		</div>
	</div>
</template>

<script>
import axios from "axios";

export default {
	data() {
		return {
			out: false,
			txt_: [],
			yy: true,
			ay: false,
			options: [
				{
					value: "1",
					label: "English",
				},
				{
					value: "2",
					label: "العربية",
				},
			],
			value: "English",
			poster_url: "",
			google: "",
			app: "",
		};
	},
	beforeCreate() {
		if (/Android|webOS|iPhone|iPod|BlackBerry/i.test(navigator.userAgent)) {
			window.location.href = "https://www.youstar.live/mobile/index.html";
		}
	},
	created() {
		axios("https://apiv2.qmovies.tv/activity/officialSite?type=2").then((res) => {
			if (res.data.code == 0) {
				this.poster_url = res.data.data.poster_url;
				let url = res.data.data.address;
				url = url.split(",");
				this.app = url[0];
				this.google = url[1];
			}
		});
	},
	methods: {
		// 打开弹窗
		us(num) {
			if (num == "Abouts") {
				this.txt_ = [
					{
						en: "Youstar app and Website owned and operated by  company . You can download our app from Google Play Store for Android Devices and from Apple Store for iOS devices. Here are some Social Media Network links below you may interest. Thank you for supporting us. If you have any questions, complaints or claims with respect to the Terms or Youstar Services, they should be directed to Youstar via suopport@youstar.live",
						ar: "",
					},
				];
			} else if (num == "Contact") {
				this.txt_ = [
					{
						en: "If you have any questions or concerns, please do not hesitate to contact us. ",
						ar: "",
					},
					{
						en: "We would love to hear from you, contact us on: ",
						ar: "",
					},
					{
						en: "Email: support@yousatr.live",
						ar: "",
					},
				];
			} else if (num == "Refunds") {
				this.txt_ = [
					{
						en: "Unless otherwise specified in these Terms or required under applicable law, any Products including the following are not refundable, in any event, after purchase:",
						ar: "",
					},
					{
						en: "1.Products that are in the possession of any Users whose use of Youstar has been suspended due to a violation of these Terms or illegal acts;",
						ar: "",
					},
					{
						en: "2. Products which have been used in whole or in part (e.g., already converted into Items, partially used package Products);",
						ar: "",
					},
					{
						en: "3. Products that were purchased more than five (5) years ago;",
						ar: "",
					},
					{
						en: "4. Products that remain at the time of one (1) year from Your last use of Youstar; ",
						ar: "",
					},
					{
						en: "5. Products that are in the possession of any Users who have terminated use of Youstar or removed the Youstar App;",
						ar: "",
					},
					{
						en: "6. Diamonds which is the equivalent amount of Items that any User sent as gifts to other users, but not displayed to either user due to the instability of the communication environment or the device environment of either user;",
						ar: "",
					},
					{
						en: "7. Products that were purchased by third parties would be as a result of theft or loss of Device or caused by Your careles;",
						ar: "",
					},
					{
						en: "You may discontinue future Subscription Services at any time. Please note that if You choose to cancel Your Subscription Services after the subscription period commences, a penalty (10% of the charges corresponding to the remaining subscription period) will be incurred for cancellation, as well as any third party payment processing fees and costs proportional to the amount of subscription period that has lapsed. In addition, at the time of cancellation, if You have already used any Diamonds and/or Items that You received as part of the Subscription Services (including Diamonds and/or Items that are provided as free gifts for Subscription Services), the costs for such used Diamonds and/or Items may be deducted from the amount that is refunded to You. When Subscription Services have been duly cancelled, Diamonds and/or Items that were granted as part of such Subscription Services may be reclaimed by the Company.",
						ar: "",
					},
					{
						en: "In addition, refunds for any and all Products as permitted under the applicable laws of Your jurisdiction will be processed, and may be limited, in accordance with the refund policies of the third party providing the payment processing services (i.e., Application Stores) that You used to purchase the Product. Therefore, We recommended that You check the respective refund provisions and policies of such third parties.",
						ar: "",
					},
				];
			} else {
				this.txt_ = [
					{
						en: "In the event of any concerns or complaints about possible violation of intellectual property rights, please send us an email at  support@youstar.live , identifying with specificity the rights alleged to be violated and the accused product(s).",
						ar: "",
					},
					{
						en: "（This email address is just for intellectual property rights and legal issues. Other requests may not get response.）",
						ar: "",
					},
				];
			}

			this.out = true;
		},
		// 语言切换
		isLanguage() {
			if (this.value == 1) {
				this.yy = true;
				this.ay = false;
			}
			if (this.value == 2) {
				this.yy = false;
				this.ay = true;
			}
		},
		download(str) {
			if (str == "google") {
				fbq("trackCustom", "googleClick");
				window.location.href = this.google;
			}
			if (str == "app") {
				fbq("trackCustom", "appClick");
				window.location.href = this.app;
			}
			if (str == "apk") {
				fbq("trackCustom", "apkClick");
				window.location.href = this.poster_url;
			}
			if (str == "huawei") {
				fbq("trackCustom", "huaweiClick");
				window.location.href =
					"https://appgallery.huawei.com/#/app/C103185171";
			}
			if (str == "service") {
				window.location.href =
					"https://www.youstar.top/about/terms_of_service.html";
			}
			if (str == "Policy") {
				window.location.href =
					"https://www.youstar.top/youstar/policy/youstarpolicy.html";
			}
			if (str == "Contact") {
				window.location.href =
					"https://www.youstar.top/youstar/policy/contact_us.html";
			}
			if (str == "Abouts") {
				window.location.href =
					"https://www.youstar.top/youstar/policy/abouts_us.html";
			}
			if (str == "Refunds") {
				window.location.href =
					"https://www.youstar.top/youstar/policy/refunds_policy.html";
			}
			if (str == "Intellectual") {
				window.location.href =
					"https://www.youstar.top/youstar/policy/intellectual_property_rights.html";
			}
		},
	},
};
</script>

<style>
html,
body {
	margin: 0;
	padding: 0;
	background-color: #fffc00;
}
#app {
	width: 100vw;
	height: 100vh;
	background-color: #fffc00;
}
.bg {
	width: 100%;
	height: 77.2vh;
	background-color: #fffc00;
}
.w {
	width: 59.7vw;
	margin: 0 auto;
}
.navb {
	display: flex;
	justify-content: space-between;
	padding-top: 3.6vh;
	height: 10.1vh;
}
.el-select .el-input__inner {
	border: none;
	background-color: #fffc00;
}
.navb-left {
	width: 12.2vw;
	height: 5.6vw;
	background: url("./assets/youstar.png") no-repeat;
	background-size: 100% auto;
}
.el-select {
	width: 100px;
}
.bottom-filex {
	position: fixed;
	bottom: 0;
	width: 100vw;
	height: 28.7vh;
	background-color: #fff;
}
.phone-box-hind {
	position: absolute;
	z-index: 100;
	left: 69.8vw;
	top: 12.5vh;
	width: 17.8vw;
	height: 38.4vw;
	border: 0.7vw solid #000;
	background-color: #000;
	border-radius: 3vw;
	overflow: hidden;
	transform: scale(0.8);
}
.phone-box-hind_a {
	position: absolute;
	z-index: 100;
	right: 69.8vw;
	top: 12.5vh;
	width: 17.8vw;
	height: 38.4vw;
	border: 0.7vw solid #000;
	background-color: #000;
	border-radius: 3vw;
	overflow: hidden;
	transform: scale(0.8);
}
.phone-box {
	margin: 0 auto;
	width: 17.8vw;
	height: 38.4vw;
}
.phone-box .bg-box {
	position: absolute;
	left: 50%;
	top: 50%;
	transform: translate(-50%, -50%);
	z-index: 100;
	width: 18.8vw;
	height: 38.4vw;
	background: url("./assets/border.png") no-repeat;
	background-size: 100% auto;
}
.phone-box img {
	width: 100%;
}
.text {
	margin-top: 2vw;
	font-size: 2.5vw;
	width: 39.2vw;
}
.text_a {
	text-align: end;
	margin-left: 20.5vw;
}
.connection {
	position: absolute;
	left: 5.3vw;
	top: 2.9vh;
	width: 24vw;
	height: 8.2vw;
	background: url("./assets/组8.png") no-repeat;
	background-size: 100% auto;
}
@keyframes pulse1 {
	0% {
	}
	70% {
		/* transform: scale(5);  我们不要用scale 因为他会让 阴影变大*/
		width: 5vw;
		height: 5vw;
		opacity: 0.6;
	}
	100% {
		width: 6.2vw;
		height: 6.2vw;
		opacity: 0;
	}
}
.picture_1 div[class^="pulse"] {
	/* 保证我们小波纹在父盒子里面水平垂直居中 放大之后就会中心向四周发散 */
	position: absolute;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);
	width: 3.5vw;
	height: 3.5vw;
	background-color: #fff;
	border-radius: 50%;
	animation: pulse1 4.5s linear infinite;
}

@keyframes pulse2 {
	0% {
	}
	70% {
		/* transform: scale(5);  我们不要用scale 因为他会让 阴影变大*/
		width: 3.8vw;
		height: 3.8vw;
		opacity: 0.6;
	}
	100% {
		width: 4.8vw;
		height: 4.8vw;
		opacity: 0;
	}
}
.picture_2 div[class^="pulse"] {
	/* 保证我们小波纹在父盒子里面水平垂直居中 放大之后就会中心向四周发散 */
	position: absolute;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);
	width: 2.8vw;
	height: 2.8vw;
	background-color: #fff;

	border-radius: 50%;
	animation: pulse2 4.5s linear infinite;
}

@keyframes pulse3 {
	0% {
	}
	70% {
		/* transform: scale(5);  我们不要用scale 因为他会让 阴影变大*/
		width: 6.9vw;
		height: 6.9vw;
		opacity: 0.6;
	}
	100% {
		width: 8vw;
		height: 8vw;
		opacity: 0;
	}
}
.picture_3 div[class^="pulse"] {
	/* 保证我们小波纹在父盒子里面水平垂直居中 放大之后就会中心向四周发散 */
	position: absolute;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);
	width: 4.8vw;
	height: 4.8vw;
	background-color: #fff;

	border-radius: 50%;
	animation: pulse3 4.5s linear infinite;
}

@keyframes pulse4 {
	0% {
	}
	70% {
		/* transform: scale(5);  我们不要用scale 因为他会让 阴影变大*/
		width: 5vw;
		height: 5vw;
		opacity: 0.6;
	}
	100% {
		width: 6vw;
		height: 6vw;
		opacity: 0;
	}
}
.picture_4 div[class^="pulse"] {
	/* 保证我们小波纹在父盒子里面水平垂直居中 放大之后就会中心向四周发散 */
	position: absolute;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);
	width: 3.3vw;
	height: 3.3vw;
	background-color: #fff;

	border-radius: 50%;
	animation: pulse4 4.5s linear infinite;
}

@keyframes pulse5 {
	0% {
	}
	70% {
		/* transform: scale(5);  我们不要用scale 因为他会让 阴影变大*/
		width: 5vw;
		height: 5vw;
		opacity: 0.6;
	}
	100% {
		width: 6vw;
		height: 6vw;
		opacity: 0;
	}
}
.picture_5 div[class^="pulse"] {
	/* 保证我们小波纹在父盒子里面水平垂直居中 放大之后就会中心向四周发散 */
	position: absolute;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);
	width: 3.1vw;
	height: 3.1vw;
	background-color: #fff;
	border-radius: 50%;
	animation: pulse5 4.5s linear infinite;
}
.world div.pulse2 {
	animation-delay: 1.5s;
}

.world_a div.pulse2 {
	animation-delay: 1.5s;
}

.world div.pulse3 {
	animation-delay: 3s;
}

.world_a div.pulse3 {
	animation-delay: 3s;
}

.world {
	position: absolute;
	left: 18.8vw;
	top: 19.2vw;
	/* margin-top: 4.7vw; */
	width: 36.4vw;
	height: 17.8vw;
	background: url("./assets/world.png") no-repeat;
	background-size: 100% auto;
}
.world_a {
	position: absolute;
	top: 19.2vw;
	right: 18.8vw;
	width: 36.4vw;
	height: 17.8vw;
	background: url("./assets/world.png") no-repeat;
	background-size: 100% auto;
}
.picture_1 {
	position: absolute;
	left: 2.9vw;
	top: 2.3vw;
	width: 3.5vw;
	height: 3.5vw;
}
.picture_1 .photo {
	position: relative;
	width: 3.5vw;
	height: 3.5vw;
	z-index: 999;
	background: url("./assets/picture_1.png") no-repeat;
	background-size: 100% auto;
}
.picture_2 {
	position: absolute;
	left: 7.1vw;
	top: 9.7vw;
	width: 2.8vw;
	height: 2.7vw;
}
.picture_2 .photo {
	position: relative;
	width: 2.8vw;
	height: 2.7vw;
	z-index: 999;
	background: url("./assets/picture_2.png") no-repeat;
	background-size: 100% auto;
}
.picture_3 {
	position: absolute;
	left: 13.1vw;
	top: 7.6vw;
	width: 4.8vw;
	height: 4.8vw;
}
.picture_3 .photo {
	position: relative;
	width: 4.8vw;
	height: 4.8vw;
	z-index: 999;
	background: url("./assets/picture_3.png") no-repeat;
	background-size: 100% auto;
}
.picture_4 {
	position: absolute;
	left: 19.5vw;
	top: 1.2vw;
	width: 3.3vw;
	height: 3.3vw;
}
.picture_4 .photo {
	position: relative;
	width: 3.3vw;
	height: 3.3vw;
	z-index: 999;
	background: url("./assets/picture_4.png") no-repeat;
	background-size: 100% auto;
}
.picture_5 {
	position: absolute;
	left: 28.8vw;
	top: 7.3vw;
	width: 3.1vw;
	height: 3.1vw;
}
.picture_5 .photo {
	position: relative;
	z-index: 999;
	width: 3.1vw;
	height: 3.1vw;
	background: url("./assets/picture_5.png") no-repeat;
	background-size: 100% auto;
}

.download {
	padding-top: 2vw;
}

.download div {
	float: left;
	width: 10.3vw;
	height: 3.2vw;
	cursor: pointer;
}
.download_a div {
	float: right;
}
.app-store {
	margin: 0 2.4vw;
	background: url("./assets/app_store.png") no-repeat;
	background-size: 100% auto;
}
.goodle-paly {
	background: url("./assets/googleplay.png") no-repeat;
	background-size: 100% auto;
}

.apk {
	background: url("./assets/apk.png") no-repeat;
	background-size: 100% auto;
}

.huawei {
	margin: 0 2.4vw 0 0;
	background: url("./assets/huaweia.png") no-repeat;
	background-size: 100% auto;
}

.app-store_a {
	margin: 0 2.4vw;
	background: url("./assets/app_store_a.png") no-repeat;
	background-size: 100% auto;
}

.goodle-paly_a {
	background: url("./assets/googleplay_a.png") no-repeat;
	background-size: 100% auto;
}

.apk_a {
	background: url("./assets/apk_a.png") no-repeat;
	background-size: 100% auto;
}

.huawei_a {
	margin: 0 0 0 2.4vw;
	background: url("./assets/huawei.png") no-repeat;
	background-size: 100% auto;
}

.el-carousel__item h3 {
	color: #475669;
	font-size: 14px;
	opacity: 0.75;
	line-height: 300px;
	margin: 0;
}
.sevice,
.sevices {
	width: 100vw;
	list-style: none;
	display: flex;
	font-size: 1vw;
	margin: 13vh 0 4vh;
	justify-content: center;
	align-items: center;
	text-align: center;
}
.sevice > p {
	margin: 0 10px;
}
.sevice > p:hover {
	cursor: pointer;
}
.sevices > p {
	margin: 0 10px;
}
.sevices > p:hover {
	cursor: pointer;
}
.sevices {
	text-align: right;
	direction: rtl;
}
.contact {
	width: 100vw;
	display: flex;
	justify-content: center;
	align-items: center;
	text-align: center;
	color: #b9b9b9;
	cursor: pointer;
}
a {
	font-size: 0.7vw;
	text-decoration: none;
	color: #b9b9b9;
}

.version {
	width: 100vw;
	margin: 1vh 0 0;
	text-align: center;
	display: block;
	font-size: 0.7vw;
	color: #b9b9b9;
}
.Abouts {
	width: 10vw;
	margin: 1vh auto;
	text-align: center;
	display: flex;
	justify-content: space-around;
	font-size: 0.7vw;
	text-align: center;
	color: #b9b9b9;
	cursor: pointer;
}
.Aboutss {
	width: 10vw;
	margin: 1vh auto;
	text-align: center;
	display: flex;
	justify-content: space-around;
	font-size: 0.7vw;
	text-align: center;
	color: #b9b9b9;
	cursor: pointer;
	text-align: right;
	direction: rtl;
}
.eject {
	position: fixed;
	top: 0;
	width: 100vw;
	height: 100vh;
	background: #000;
	z-index: 1000;
	opacity: 0.8;
}
.eject_center {
	position: absolute;
	left: 10vw;
	top: 10vh;
	font-size: 23px;
	/* transform: translate(-50%, -50%); */
	width: 80vw;
	color: #fff;
	z-index: 1001;
	padding: 20px 0 0;
}
.boble {
	width: 90%;
	padding: 0 50px;
	word-wrap: break-word;
	margin: 0 0 5vh;
}
.bobles {
	width: 90%;
	padding: 0 50px;
	word-wrap: break-word;
	margin: 0 0 5vh;
	text-align: right;
	direction: rtl;
}
.imgs {
	position: absolute;
	top: 0;
	right: 0;
	width: 30px;
	opacity: 0.6;
	cursor: pointer;
}
</style>
